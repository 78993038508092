%c-input-set {
  background-color: #f2f2f2;
  border-radius: rr(6);
  font-size: rr(16);
  padding: rr(8) rr(12);
  width: 100%;
  color: var(--black__primary--hex);
  @include media-query-small {
    font-size: rr(20);
  }
}

[class^="c-input"] {
  outline: 0;
}

.c-input {
  // .c-input--text
  &--text {
    @extend %c-input-set;
    @include media-query-small {
    }
  }
  // .c-input--select
  &--select {
    @extend %c-input-set;
    padding: 0;
    border: 1px solid var(--gray__primary--hex);
    background-color: transparent;
    position: relative;
    &::before {
      position: absolute;
      display: block;
      content: "";
      width: rr(8);
      height: rr(8);
      top: 50%;
      right: rr(16);
      margin-top: rr(-2);
      border-bottom: 2px solid var(--black__primary--hex);
      border-right: 2px solid var(--black__primary--hex);
      transform: rotate(45deg) translateY(-50%);
      transform-origin: 50% 0;
      pointer-events: none;
    }
    select {
      padding: rr(8) rr(12);
      -webkit-appearance: none;
      appearance: none;
      outline: none;
      width: 100%;
      color: inherit;
    }
  }
  // .c-input--radio
  &--radio {
    input {
      box-sizing: border-box;
      padding: 0;
      position: absolute;
      opacity: 0;
    }
    label {
      cursor: pointer;
      position: relative;
      padding-left: rr(28);
      display: inline-block;
      @include media-query-small {
        font-size: rr(20);
      }
      &::before {
        border-radius: 100%;
        border: 1px solid var(--gray__primary--hex);
        content: "";
        cursor: pointer;
        display: inline-block;
        width: rr(18);
        height: rr(18);
        text-align: center;
        transition: all 250ms ease;
        position: absolute;
        top: rr(3);
        left: 0;
        @include media-query-small {
          top: rr(7);
        }
      }
    }
    input:checked + label:before {
      background-color: var(--primary__color--hex);
      box-shadow: inset 0 0 0 2px var(--white__primary--hex);
    }
  }
  // .c-input--text-area
  &--text-area {
    @extend %c-input-set;
    line-height: 1.8;
    overflow: hidden;
    resize: none;
    min-height: rr(240);
    overflow-y: scroll;
  }
  // .c-input--checkbox
  &--checkbox {
    input {
      position: absolute;
      opacity: 0;
    }
    label {
      box-sizing: border-box;
      cursor: pointer;
      display: inline-block;
      padding-left: rr(30);
      position: relative;
      width: auto;
      @include media-query-small {
        font-size: rr(20);
      }
      &::before {
        background: var(--white__primary--hex);
        border: 1px solid var(--gray__primary--hex);
        content: "";
        display: block;
        height: rr(18);
        left: 0;
        margin-top: rr(-8);
        position: absolute;
        top: rr(11);
        width: rr(18);
        border-radius: rr(4);
        transition: all 250ms ease;
        @include media-query-small {
          top: rr(14);
        }
      }
      &::after {
        border-right: 2px solid var(--white__primary--hex);
        border-bottom: 2px solid var(--white__primary--hex);
        content: "";
        display: block;
        height: rr(10);
        left: rr(6);
        margin-top: rr(-6);
        opacity: 0;
        position: absolute;
        top: rr(11);
        transform: rotate(45deg);
        width: rr(6);
        @include media-query-small {
          top: rr(14);
        }
      }
    }
    input:checked + label::before {
      background-color: var(--primary__color--hex);
      border-color: var(--primary__color--hex);
    }
    input:checked + label::after {
      opacity: 1;
    }
  }
  // .c-input--file
  &--file {
    justify-self: start;
    display: inline-block;
    background-color: var(--primary__color--hex);
    border-radius: 6px;
    & + & {
      @include media-query-small {
        margin-top: rr(16);
      }
    }
    input {
      display: none;
    }
    label {
      @include inversion_main_color;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: rr(8) rr(20);
      font-size: rr(15);
      color: var(--primary__color--hex);
      @include media-query-small {
        font-size: rr(18);
      }
    }
  }
}
